import type { ReactNode } from 'react';
import { LinkedinOriginalIcon } from 'react-devicons';

type IHeroAvatarProps = {
    title: ReactNode;
    description: ReactNode;
    avatar: string;
    socialButtons: string[];
};

const HeroAvatar = (props: IHeroAvatarProps) => (
    <div className='flex flex-col items-center md:flex-row md:justify-between md:gap-x-24'>
        <div>
            <h1 className='text-3xl font-bold'>{props.title}</h1>

            <p className='mt-6 text-xl leading-9 text-justify'>{props.description}</p>

            <div className='mt-3 flex gap-1'>
                {props.socialButtons.map((button) => (
                    <a
                        key={button}
                        href={button}
                        className='text-gray-400 hover:text-gray-500'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <LinkedinOriginalIcon className='text-4xl' />
                    </a>
                ))}
            </div>
        </div>

        <div className='shrink-0'>
            <img src={props.avatar} className='h-64 w-64' />
        </div>
    </div>
);

export { HeroAvatar };
