import ProjectList from './partials/ProjectList';
import StackList from './partials/StackList';
import Hero from './partials/Hero';
import Footer from './partials/Footer';
import Gallery from './partials/Gallery';
import PROJECTS from './api/projects';
import STACK from './api/stack';

const App = () => {
    return (
        <div className='bg-slate-900 h-fit min-w-full'>
            <Hero />
            <ProjectList title='Projetos recentes' projects={PROJECTS} />
            <StackList title='Tecnologias que trabalho' techs={STACK} />
            {/* <Gallery title='Galeria' images={[]} /> */}
            <Footer />
        </div>
    );
};

export default App;
