import { Section } from '../components/Section';
import { HeroAvatar } from '../components/HeroAvatar';

const Hero = () => {
    return (
        <Section>
            <HeroAvatar
                title={
                    <div className='text-slate-50'>
                        Olá, sou o <span className='text-red-600'>Jackson</span> 👋
                    </div>
                }
                description={
                    <p className='text-white'>
                        Como desenvolvedor Full Stack, tenho mais de três anos de experiência na construção de
                        aplicações e websites com PHP, JavaScript e tecnologias relacionadas. Eu também tenho
                        experiência trabalhando com Frameworks (Laravel, Next.js, Express.js), Servidores Linux e
                        plataformas Serverless. Meu projeto mais recente foi criar este website usando React e AWS -
                        Amplify.
                    </p>
                }
                avatar='assets/img/personagem.png'
                socialButtons={['https://www.linkedin.com/in/jackson-marcondes/']}
            />
        </Section>
    );
};

export default Hero;
