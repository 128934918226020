import Stack from '../components/Stack';
import { Section } from '../components/Section';

type IProjectListProps = {
    title: string;
    techs: string[];
};

const ProjectList = (props: IProjectListProps) => {
    return (
        <Section title={props.title}>
            <div className='flex flex-row items-center gap-x-8 rounded-md bg-slate-800 flex-wrap justify-center'>
                {props.techs.map((tech, index) => (
                    <Stack key={index} name={tech} />
                ))}
            </div>
        </Section>
    );
};

export default ProjectList;
