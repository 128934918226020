import ProjectTypes from './types';
import {
    PhpOriginalIcon,
    FirebasePlainIcon,
    GooglecloudOriginalIcon,
    ReactOriginalIcon,
    NodejsOriginalIcon,
    AmazonwebservicesOriginalIcon,
    DockerOriginalIcon,
    SocketioOriginalIcon,
    JavascriptOriginalIcon,
    LaravelPlainIcon,
    MysqlOriginalIcon,
} from 'react-devicons';

const SIZE = '8em';
const ANIMATION = 'hover:animate-pulse hover:translate-y-2';

const Project = ({ name }: ProjectTypes) => (
    <div className={`p-3 text-center ${ANIMATION}`}>
        {name === 'php' && <PhpOriginalIcon size={SIZE} />}
        {name === 'firebase' && <FirebasePlainIcon size={SIZE} />}
        {name === 'gcp' && <GooglecloudOriginalIcon size={SIZE} />}
        {name === 'react' && <ReactOriginalIcon size={SIZE} />}
        {name === 'nodejs' && <NodejsOriginalIcon size={SIZE} />}
        {name === 'aws' && <AmazonwebservicesOriginalIcon size={SIZE} />}
        {name === 'docker' && <DockerOriginalIcon size={SIZE} />}
        {name === 'socket.io' && <SocketioOriginalIcon size={SIZE} />}
        {name === 'javascript' && <JavascriptOriginalIcon size={SIZE} />}
        {name === 'laravel' && <LaravelPlainIcon size={SIZE} />}
        {name === 'mysql' && <MysqlOriginalIcon size={SIZE} />}
        <span className='capitalize text-slate-50'>{name}</span>
    </div>
);

export default Project;
