const PROJECTS = [
    {
        'name': 'Cardápios Vip',
        'description': 'Cardápios Vip é uma aplicação que lista vários restaurantes, bares e outros negócios gastronômicos com base na cidade em que o usuário se encontra. O objetivo deste projeto é fornecer informações sobre a melhor comida local com base apenas em análises reais feitas pelos clientes. Ele utiliza os recursos do Google Places para consumir e atualizar os estabelecimentos de forma automatizada. Ele é desenvolvido em PHP com o framework Laravel.',
        'url': 'https://www.cardapiosvip.com.br/',
        'techList': ['PHP', 'Laravel', 'GCP','AWS'],
        'image': 'assets/img/cardapiosvip_150x150.png',
    },
        {
        'name': 'Só por hoje eu...',
        'description': 'Só por hoje eu, é um lugar onde você pode compartilhar de forma anônima seus pensamentos e sentimentos, independentemente de estar se sentindo pra baixo ou feliz. Quer seja um bom conselho sobre como passar o dia, ou apenas alguém lhe dizendo que o apoia, é sempre bom ter algum apoio nestes tempos!',
        'url': 'https://www.soporhojeeu.com.br/',
        'techList': ['Firebase','Javascript','Serverless'],
        'image': 'assets/img/soporhojeeu_150x150.png',
    },
        {
        'name': 'Convite de casamento',
        'description': 'Desenvolvi um website que serve como meu convite de casamento. Ele usa o serviço Amplify da AWS para hospedagem, implantação e automatização do fluxo de desenvolvimento. Meu objetivo inicial era aprender como usar o Amplify da AWS. Este projeto me ensinou sobre design e arquitetura de software.',
        'url': 'https://bit.ly/casamento-dai-jack-convite',
        'techList': ['Amplify', 'AWS','Serverless'],
        'image': 'assets/img/sitecasamento_150x150.png',
    },
]

export default PROJECTS;
