import { Section } from '../components/Section';
import { currentYear } from '../utils/Utils';

const Footer = () => (
    <Section>
        <div className='text-slate-50 text-center'>
            © Copyright {currentYear} Jackson Marcondes. Layout baseado no projeto{' '}
            <a
                href='https://github.com/ixartz/Astro-boilerplate'
                target='blank'
                className='underline decoration-cyan-400'
            >
                Astro boilerplate{' '}
            </a>
        </div>
    </Section>
);

export default Footer;
