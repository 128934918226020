import ProjectTypes from './types';
import techColors from '../../utils/TechColorsUtil';

const Project = ({ name, techList, description, url, image }: ProjectTypes) => (
    <div className='flex flex-col items-center gap-x-8 rounded-md bg-slate-800 p-3 md:flex-row'>
        <div className='shrink-0'>
            <a href={url} target='blank'>
                <img className='h-36 w-36 hover:translate-y-1' src={image} alt='teste' loading='lazy' />
            </a>
        </div>

        <div>
            <div className='flex flex-col items-center gap-y-2 md:flex-row'>
                <a href={url} target='blank'>
                    <div className='hover:text-cyan-400 text-xl font-semibold text-slate-50'>{name}</div>
                </a>

                <div className='ml-3 flex gap-2'>
                    {techList.map((tech) => (
                        <div key={tech} className={`${techColors(tech)} rounded-md px-2 py-1 text-xs font-semibold`}>
                            {tech}
                        </div>
                    ))}
                </div>
            </div>

            <p className='mt-3 text-gray-400 text-justify mb-3'>{description}</p>
            <a
                href={url}
                target='blank'
                className='shrink-0 rounded-md bg-gradient-to-br from-sky-500 to-cyan-400 p-2 text-sm font-medium hover:from-sky-700 hover:to-cyan-600 focus:outline-none focus:ring-2 focus:ring-cyan-600/50'
            >
                Acessar
            </a>
        </div>
    </div>
);

export default Project;
