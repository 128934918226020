const techColors = (tech: string) => {
    switch (tech) {
        case 'React':
            return 'bg-blue-400 text-blue-900';
        case 'TypeScript':
            return 'bg-green-400 text-green-900';
        case 'Node':
            return 'bg-orange-400 text-orange-900';
        case 'AWS':
            return 'bg-purple-400 text-purple-900';
        case 'Amplify':
            return 'bg-indigo-400 text-indigo-900';
        case 'Javascript':
            return 'bg-yellow-400 text-yellow-900';
        case 'PHP':
            return 'bg-red-400 text-red-900';
        case 'Laravel':
            return 'bg-teal-400 text-teal-900';
        case 'GCP':
            return 'bg-pink-400 text-pink-900';
        case 'Firebase':
            return 'bg-indigo-400 text-indigo-900';
        case 'Serverless':
            return 'bg-orange-400 text-orange-900';
        case 'S3':
            return 'bg-rose-400 text-rose-900';
        default:
            return 'bg-gray-400 text-gray-900';
    }
};

export default techColors;
