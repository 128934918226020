import Project from '../components/Project';
import { Section } from '../components/Section';

type IProjectListProps = {
    title: string;
    projects: {
        name: string;
        description: string;
        url: string;
        techList: string[];
        image: string;
    }[];
};

const ProjectList = (props: IProjectListProps) => {
    return (
        <Section title={props.title}>
            <div className='flex flex-col gap-6 '>
                {props.projects.map((project) => (
                    <Project
                        key={project.name}
                        name={project.name}
                        techList={project.techList}
                        description={project.description}
                        url={project.url}
                        image={project.image}
                    />
                ))}
            </div>
        </Section>
    );
};

export default ProjectList;
